import { HcpConsentType, PatientConsentType } from '../enums/consent.enum';

// Collection of the patients consents
export const PATIENT_CONSENTS = { [PatientConsentType.TreatmentMatchingConsent]: 1, [PatientConsentType.TermsOfUseConsent]: 28 };

// Collection of the hcp consents
export const HCP_CONSENTS = {
  [HcpConsentType.EmailPreference]: 10,
  [HcpConsentType.AiContactConsent]: 11,
  [HcpConsentType.AiUsageConsent]: 12,
};
