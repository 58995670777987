import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT, Environment } from '@my-tomorrows/core-environment';
import { Observable, map } from 'rxjs';
import { ApiResponse } from '../shared/interfaces/api-response.interface';
import { ChangePasswordApiResponse, ChangePasswordRequest, HcpDetailsApi } from './models';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsApiService {
  private readonly httpClient = inject(HttpClient);
  private readonly environment: Environment = inject(ENVIRONMENT);

  private readonly url = this.environment.microservicesApiUrl;

  getHcpDetails(hcpUserId: string): Observable<HcpDetailsApi> {
    return this.httpClient.get<HcpDetailsApi>(`${this.url}/health-care-professional/${hcpUserId}`, { withCredentials: true });
  }

  updateHcpAccount(uuid: string, payload: Partial<HcpDetailsApi>): Observable<void> {
    return this.httpClient.patch<void>(`${this.url}/health-care-professional/${uuid}`, payload, { withCredentials: true });
  }

  requestHcpDeleteAccount(): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/health-care-professional/request-deletion`, {}, { withCredentials: true });
  }

  requestPatientDeleteAccount(): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/patient/request-deletion`, {}, { withCredentials: true });
  }

  changePassword(payload: ChangePasswordRequest): Observable<ChangePasswordApiResponse> {
    return this.httpClient
      .patch<ApiResponse<ChangePasswordApiResponse>>(`${this.url}/account/password`, payload, { withCredentials: true })
      .pipe(map((data: ApiResponse<ChangePasswordApiResponse>) => data.details));
  }
}
