export function checkIfToday(date: string): boolean {
  const todayDateString = new Date().toDateString();
  const givenDateString = new Date(date).toDateString();
  return todayDateString === givenDateString;
}

export function checkIfSameDay(date1: string, date2: string): boolean {
  if (!date1 || !date2) {
    return false;
  }
  const todayDateString = new Date(date1).toDateString();
  const givenDateString = new Date(date2).toDateString();

  return todayDateString === givenDateString;
}

export function getDateDifferenceFromToday(date: string): {
  daysDifference: number;
  yearsDifference: number;
} {
  if (isNaN(Date.parse(date))) {
    throw new Error('Invalid date format. Please provide a valid date string.');
  }

  const today = new Date();
  const targetDate = new Date(date);

  // Set both dates to the start of the day to avoid discrepancies
  today.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);

  const millisecondsInDay = 86400000;
  const daysDifference = (today.getTime() - targetDate.getTime()) / millisecondsInDay;
  const yearsDifference = today.getFullYear() - targetDate.getFullYear();

  return { daysDifference, yearsDifference };
}

/**
 * Return DD MMM YYYY format
 */
export function getFullDate(date: string, locale = 'default', separator = ' ') {
  const dateObj = new Date(date);
  const day = dateObj.toLocaleString(locale, { day: '2-digit' });
  const month = dateObj.toLocaleString(locale, { month: 'short' });
  const year = dateObj.toLocaleString(locale, { year: 'numeric' });

  return `${day}${separator}${month}${separator}${year}`;
}

/**
 * Return DD MMM format
 */
export function getDayMonth(date: string, locale = 'default', separator = ' ') {
  const dateObj = new Date(date);
  const day = dateObj.toLocaleString(locale, { day: '2-digit' });
  const month = dateObj.toLocaleString(locale, { month: 'short' });

  return `${day}${separator}${month}`;
}
