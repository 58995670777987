export enum HcpProfession {
  Nurse = 'nurse',
  NursePractitioner = 'nurse_practitioner',
  Pharmacist = 'pharmacist',
  PharmacistTechnician = 'pharmacist_technician',
  Physician = 'physician',
  PhysicianAssistant = 'physician_assistant',
  ResearchAssistant = 'research_assistant',
  ResearchFellow = 'research_fellow',
  StudyDirector = 'study_director',
  Other = 'other',
}
