import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, WritableSignal, computed, inject, signal } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountSettingsApiService, AuthApiService } from '@my-tomorrows/api';
import { CurrentUser, UserType, mapCurrentUser } from '@my-tomorrows/shared-utils';
import { EMPTY, Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { CURRENT_USER_STORAGE_KEY, DEFAULT_USER } from '../../constants/auth.constants';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SharedAuthService {
  private readonly authHttpService = inject(AuthApiService);
  private readonly storageService = inject(StorageService);
  private readonly accountSettingsApiService = inject(AccountSettingsApiService);
  private readonly msalService = inject(MsalService);

  private $user: WritableSignal<CurrentUser> = signal(this.storageService.getItem(CURRENT_USER_STORAGE_KEY) || DEFAULT_USER);
  readonly $getUser = computed<CurrentUser>(() => this.$user());
  readonly $getUserType = computed<UserType>(() => this.$user().userType);

  constructor() {
    this.setDefaultCurrentUser();
  }

  private setDefaultCurrentUser(): void {
    if (!this.storageService.hasItem(CURRENT_USER_STORAGE_KEY)) {
      this.storageService.setItem(CURRENT_USER_STORAGE_KEY, DEFAULT_USER);
    }
  }

  setUser(user: CurrentUser): void {
    this.storageService.setItem(CURRENT_USER_STORAGE_KEY, user);

    this.$user.set(user);
  }

  setDefaultUser(): void {
    this.setUser(DEFAULT_USER);
  }

  logout(): Observable<void> {
    if (this.msalService.instance.getAllAccounts()?.[0]) {
      return this.ssoLogout();
    } else {
      return this.mainLogout();
    }
  }

  ssoLogout(): Observable<void> {
    return this.msalService.logout({ postLogoutRedirectUri: '/internal-login' });
  }

  mainLogout(): Observable<void> {
    return this.authHttpService.logout().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.setDefaultUser();
        }
        return EMPTY;
      }),
      tap(() => this.setDefaultUser()),
    );
  }

  fetchCurrentUser(): Observable<CurrentUser> {
    return this.authHttpService.getCurrentUser().pipe(
      map(mapCurrentUser),
      switchMap((user: CurrentUser) => {
        return user.userType === UserType.hcp
          ? this.accountSettingsApiService.getHcpDetails(user.uuid as string).pipe(
              map((hcpDetailsResponse) => ({
                ...user,
                isCrtMember: hcpDetailsResponse.is_involved_in_clinical_trial,
              })),
            )
          : of(user);
      }),
    );
  }
}
