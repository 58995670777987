import { Consent, ConsentApi } from '@my-tomorrows/api';

/**
 *
 * Function accepts a consent api response observable as parameter and map it to the presentational format.
 */
export function mapConsentResponse(response: ConsentApi[]): Consent[] {
  return response.map((consent: ConsentApi) => ({
    ...consent,
    consentTypeId: consent.id_consent_type,
  }));
}
