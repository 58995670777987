import { AbstractControl, FormGroup } from '@angular/forms';

export function changeTouchState(controls: AbstractControl[], touched = true) {
  controls.forEach((control) => {
    if (touched) {
      control.markAsTouched();
    } else {
      control.markAsUntouched();
    }
    control.updateValueAndValidity();

    const childControls = (control as FormGroup).controls;
    if (childControls) {
      changeTouchState(Object.values(childControls), touched);
    }
  });
}
