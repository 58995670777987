import { DataState } from './state-helpers.interface';

export function setLoading<K>(data?: K): DataState<K> {
  return {
    data: data || undefined,
    loading: true,
    error: false,
  };
}

export function setSuccess<K>(data: K): DataState<K> {
  return {
    data,
    loading: false,
    error: false,
  };
}

export function setError<K>(): DataState<K> {
  return {
    data: undefined,
    loading: false,
    error: true,
  };
}
