/**
 *
 * @param array
 * @returns array
 *
 * Removes duplicate array items
 */
export function removeDuplicates<K>(array: Array<K>): Array<K> {
  return [...new Set(array)];
}
