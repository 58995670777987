import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanMatchFn, Route } from '@angular/router';
import { map } from 'rxjs';

import { UserType } from '@my-tomorrows/shared-utils';
import { RouteData } from '../../enums/route-data.enum';
import { SharedAuthService } from '../shared-auth/shared-auth.service';

export const canMatchRoleGuard: CanMatchFn = (route: Route) => {
  return toObservable(inject(SharedAuthService).$getUserType).pipe(
    map((type: UserType) => {
      const allowedUserTypes: UserType | undefined = route.data && route.data[RouteData.CanMatchRoles];

      if (!allowedUserTypes?.length) {
        return true;
      }

      return allowedUserTypes.includes(type);
    }),
  );
};
