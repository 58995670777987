/**
 * Route data allows to modify app level logics for specific route pages
 */
export enum RouteData {
  /** Allow to fit all content into the device screen. Example: chat */
  FullScreenHeight = 'fullScreenHeight',
  /** Enables Breadcrumb component */
  Breadcrumb = 'breadcrumb',
  /** Allow to hide header component */
  HideHeader = 'hideHeader',
  /** Title for HTM document per page */
  TabTitle = 'tabTitle',
  /** User role to identify if user is authenticated for a specific role*/
  Role = 'role',
  /** Used to enable use different pages for same route according to user type*/
  CanMatchRoles = 'canMatchRoles',
}
