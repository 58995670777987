import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT, Environment } from '@my-tomorrows/core-environment';
import { Observable, map } from 'rxjs';
import { ApiResponse } from '../shared/interfaces/api-response.interface';
import { CurrentUserResponse } from './models';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private readonly httpClient = inject(HttpClient);
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly url = this.environment.microservicesApiUrl;

  getCurrentUser(): Observable<CurrentUserResponse> {
    return this.httpClient
      .get<ApiResponse<CurrentUserResponse>>(`${this.url}/account/me`, { withCredentials: true })
      .pipe(map(({ details }) => details));
  }

  logout(): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/account/session`, { withCredentials: true });
  }
}
