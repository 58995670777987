import { HttpErrorResponse, HttpEventType, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ENVIRONMENT } from '@my-tomorrows/core-environment';
import { take, tap } from 'rxjs';
import { MYT_TOKEN_RENEWAL_TIME } from '../../constants/storage-keys.constant';
import { RedirectService } from '../redirect/redirect.service';
import { SharedAuthService } from '../shared-auth/shared-auth.service';
import { StorageService } from '../storage/storage.service';

interface ExceptionItem {
  url: string;
  method?: 'POST' | 'GET' | 'DELETE' | 'PUT';
}

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const redirectService = inject(RedirectService);
  const authService = inject(SharedAuthService);
  const storageService = inject(StorageService);
  const environment = inject(ENVIRONMENT);
  const dialogRef = inject(MatDialog);

  const exceptUrls: ExceptionItem[] = [
    { url: '/otp-session' },
    { url: 'account/me' },
    { url: 'account/session', method: 'POST' },
    /** User is trying to logout, therefore no need to redirect to login */
    { url: '/account/session', method: 'DELETE' },
    { url: 'eu.i.posthog.com' },
    { url: 'account/reset-password' },
  ];

  return next(req).pipe(
    tap({
      next: (event) => {
        if (event.type === HttpEventType.Response) {
          if (req.withCredentials === true && req.url.startsWith(environment.microservicesApiUrl)) {
            storageService.setItem(MYT_TOKEN_RENEWAL_TIME, new Date().getTime());
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        const isException = exceptUrls.some((i) => req.url.includes(i.url) && (i.method ? req.method === i.method : true));
        if (error.status === 401 && !isException) {
          authService
            .logout()
            .pipe(take(1))
            .subscribe({
              complete: () => {
                dialogRef.closeAll();
                redirectService.navigate('/login');
              },
            });
        }
      },
    }),
  );
};
