export enum HcpConsentType {
  EmailPreference = 'emailPreference',
  AiContactConsent = 'aiContactConsent',
  AiUsageConsent = 'aiUsageConsent',
}

export enum PatientConsentType {
  TreatmentMatchingConsent = 'treatmentMatchingConsent',
  TermsOfUseConsent = 'termsOfUseConsent',
}
