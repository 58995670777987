export enum AnalyticEvents {
  SignIn = 'sign_in',
  HcpCreateAccount = 'create_account_hp',
  PatientCreateAccount = 'create_account_pt',
  DetailsEntry = 'enter_account_details',
  VerifyEmailResend = 'verify_email_resend',
  SetPassword = 'password_activate_account',
  AccountSetup = 'complete_account_setup',
  // Referrals
  RfRequestAccess = 'rf_request_access',
  RfContactSite = 'rf_contact_site',
  RfCreateAccountPopup = 'rf_create_account_popup',
  RfLoginPopup = 'rf_login_popup',
  RfSendReferralMessage = 'rf_send_referral_message',
  AIRequestAccess = 'ai_request_access',
  AICreateAccountPopup = 'ai_create_account_popup',
  AILoginPopup = 'ai_login_popup',
}
