import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { REDIRECT_URL_STORAGE_KEY } from '../../constants/common.constants';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private readonly router = inject(Router);
  private readonly storageService = inject(StorageService);
  private excludedRoutesToRedirect = ['/login'];

  get redirectPath(): string {
    return this.storageService.getItem(REDIRECT_URL_STORAGE_KEY);
  }

  /**
   * Navigate to given route path and store the previous route in local storage
   */
  navigate(route: string, redirectPath?: string) {
    const redirect = redirectPath || this.router.url;
    if (!this.excludedRoutesToRedirect.includes(redirect)) {
      this.storageService.setItem(REDIRECT_URL_STORAGE_KEY, redirect);
    }
    this.router.navigate([route]);
  }

  /**
   * Redirect to previously stored REDIRECT_URL
   * Decode before routing to process route params
   */
  redirectToPreviousUrl() {
    const decodedUrl = decodeURIComponent(this.redirectPath || '/');
    this.router.navigateByUrl(decodedUrl);
    this.storageService.removeItem(REDIRECT_URL_STORAGE_KEY);
  }
}
