import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { ReferencesApiService, ReferencesEntity, ReferencesResponse } from '@my-tomorrows/api';
import { SelectOption } from '@my-tomorrows/components';
import { Observable, catchError, finalize, map, throwError } from 'rxjs';
import { EAP_JOURNEY_PHASE } from '../../constants/common.constants';
import { References } from '../../interfaces/references.interface';

@Injectable({
  providedIn: 'root',
})
export class ReferencesService {
  private readonly referencesApiService = inject(ReferencesApiService);

  private references: Observable<References<SelectOption<number>[]>> | null = null;
  readonly $loading: WritableSignal<boolean> = signal(false);

  getReferences(): Observable<References<SelectOption<number>[]>> {
    if (!this.references) {
      this.$loading.set(true);
      this.references = this.referencesApiService.getReferences().pipe(
        map((references: ReferencesResponse) => {
          return {
            country: this._mapReferenceEntity(references.Country),
            userDefinedSource: this._mapReferenceEntity(references.UserDefinedSource),
            specialization: this._mapReferenceEntity(references.Specialization),
            consentType: this._mapReferenceEntity(references.ConsentType),
            eapCloseReason: this._mapCloseReasonEntity(references.CloseReason, EAP_JOURNEY_PHASE),
            institution: this._mapInstitutionEntity(references.HealthcareFacility),
          };
        }),
        finalize(() => this.$loading.set(false)),
        catchError((error) => {
          this.references = null;
          this.$loading.set(false);
          return throwError(() => error);
        }),
      );
    }
    return this.references;
  }

  private _mapReferenceEntity(references: ReferencesEntity[]): SelectOption<number>[] {
    return references?.map((entity: ReferencesEntity) => ({
      title: entity.name,
      value: entity.id,
    }));
  }

  private _mapCloseReasonEntity(references: ReferencesEntity[], phase: string): SelectOption<number>[] {
    return this._mapReferenceEntity(references.filter((element) => element.phase === phase));
  }

  private _mapInstitutionEntity(references: ReferencesEntity[]): SelectOption<number>[] {
    return this._mapReferenceEntity(references);
  }
}
