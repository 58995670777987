import { CurrentUserResponse } from '@my-tomorrows/api';
import { CurrentUser, UserType } from './current-user.interface';

/**
 *
 * Map current user api response
 */
export function mapCurrentUser(user: CurrentUserResponse): CurrentUser {
  const consents = user.consents?.filter((consent) => consent.answered === true).map((consent) => consent.id_consent_type);

  return {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    userType: user.user_type as UserType,
    uuid: user.uuid,
    consents: consents || [],
  };
}
