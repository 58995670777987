import { catchError, map, Observable, of, startWith } from 'rxjs';
import { DataState } from '../state-helpers/state-helpers.interface';
import { setError, setLoading, setSuccess } from '../state-helpers/state-helpers.util';

/**
 *
 * Function accepts an observable as parameter and map the error, loading and success statuses as an manageable object
 */
export function toDataState<T>(observable: Observable<T>): Observable<DataState<T>> {
  return observable.pipe(
    map((res) => setSuccess(res)),
    startWith(setLoading<T>()),
    catchError(() => of(setError<T>())),
  );
}
