import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot } from '@angular/router';
import { CurrentUser, UserType } from '@my-tomorrows/shared-utils';
import { map } from 'rxjs';
import { PATIENT_CONSENTS } from '../../constants/consents.constants';
import { RouteData } from '../../enums/route-data.enum';
import { RedirectService } from '../redirect/redirect.service';
import { SharedAuthService } from '../shared-auth/shared-auth.service';

export function authCanActivateGuard(): CanActivateChildFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const redirectService = inject(RedirectService);
    const user$ = toObservable(inject(SharedAuthService).$getUser);

    return user$.pipe(
      map((user: CurrentUser) => {
        const type = user.userType;
        const isProfileCompleted = user.consents?.some((element) => element === PATIENT_CONSENTS.termsOfUseConsent);

        const allowedUserTypes = route.data[RouteData.Role];

        // Navigate incomplete profiles to the setup page
        if (type == UserType.patient && isProfileCompleted == false && !state.url.includes('setup-account')) {
          router.navigate(['/setup-account/patient']);
          return false;
        }

        // Allow navigation, If role is not set on the route data
        if (!allowedUserTypes?.length) {
          return true;
        }

        if (allowedUserTypes.includes(type)) {
          return true;
        } else {
          // Anonymous user tries to reach protected route
          if (type === UserType.search) {
            const loginPath = allowedUserTypes.includes(UserType.siteManager) ? 'internal-login' : 'login';
            redirectService.navigate(loginPath, state.url);
          } else {
            // A user role tries to reach a page which is not granted for this role
            // A user role tries to reach login/signup pages after login
            router.navigate(['/home']);
          }
          return false;
        }
      }),
    );
  };
}
