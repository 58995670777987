import { UserType } from '@my-tomorrows/shared-utils';
import { PostHogUserRoles } from '../../enums/posthog.enum';

export function mapPostHogUserRoles(data: { userType: UserType; isCrtMember: boolean }) {
  if (data.isCrtMember) {
    return PostHogUserRoles.HcpCrtMember;
  }

  switch (data.userType) {
    case UserType.hcp:
      return PostHogUserRoles.Hcp;
    case UserType.patient:
      return PostHogUserRoles.Patient;
    case UserType.siteManager:
      return PostHogUserRoles.SiteManager;
    case UserType.mytUser:
      return PostHogUserRoles.MytUser;
    default:
      return PostHogUserRoles.Anonymous;
  }
}
