export function downloadFileOnBrowser(response: Blob, fileName: string) {
  const blob = new Blob([response], { type: response.type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', fileName);

  link.addEventListener('touchstart', function () {
    link.click();
  });

  document.body.appendChild(link);
  link.style.display = 'none';
  link.click();
  document.body.removeChild(link);
}
