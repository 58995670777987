import { inject, Injectable } from '@angular/core';
import posthog from 'posthog-js';
import { GA_EVENT } from '../../constants/common.constants';
import { AnalyticEvents } from '../../enums/data-analytics.enum';
import { PostHogAdditionalProperties, PostHogEvents } from '../../enums/posthog.enum';
import { DataObject } from '../../interfaces/data-analytics.interface';
import { SharedAuthService } from '../shared-auth/shared-auth.service';
import { mapPostHogUserRoles } from './analytics.util';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly authService = inject(SharedAuthService);

  /**
   * Used to set custom google analytic events
   */
  pushEvent(eventName: AnalyticEvents) {
    window.dataLayer = window.dataLayer || [];
    const dataObject: DataObject = {
      event: GA_EVENT,
      event_name: eventName,
    };
    window.dataLayer.push(dataObject);
  }

  posthogCapture(eventName: PostHogEvents, additionalProps: PostHogAdditionalProperties = {}): void {
    const userRole = mapPostHogUserRoles({
      userType: this.authService.$getUserType(),
      isCrtMember: !!this.authService.$getUser().isCrtMember,
    });

    const defaultProps: PostHogAdditionalProperties = {
      user_role: userRole,
      is_internal_user: !!this.authService.$getUser().email?.includes('@mytomorrows.com'),
    };

    posthog.capture(eventName, {
      ...defaultProps,
      ...additionalProps,
    });
  }
}
