import { InjectionToken } from '@angular/core';
import { MsalConfig } from './interfaces/msal-config.interface';

export interface Environment {
  readonly production: boolean;
  readonly searchApiUrl: string;
  readonly microservicesApiUrl: string;
  readonly searchGraphQlUrl: string;
  readonly googleMapsApiKey: string;
  readonly cometChatAppId: string;
  readonly oldHcpPortalLink: string;
  readonly posthogKey: string;
  readonly disableAnimations?: boolean;
  readonly msal: MsalConfig;
  readonly datadogClientToken: string;
  readonly datadogApplicationId: string;
}

export const ENVIRONMENT = new InjectionToken<Environment>('ENVIRONMENT');
