export interface CurrentUser {
  userType: UserType;
  firstname?: string;
  lastname?: string;
  email?: string;
  uuid?: string;
  consents?: number[];
  isCrtMember?: boolean;
}

export enum UserType {
  patient = 'patient',
  hcp = 'hcp',
  search = 'search',
  siteManager = 'Eap.Admin',
  mytUser = 'mytUser',
}
