import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { ENVIRONMENT, Environment } from '@my-tomorrows/core-environment';
import { Observable, shareReplay, take } from 'rxjs';
import { ReferencesResponse } from './models/response/references.interface';

@Injectable({
  providedIn: 'root',
})
export class ReferencesApiService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient = inject(HttpClient);

  private readonly url = signal(this.environment.microservicesApiUrl);

  getReferences(): Observable<ReferencesResponse> {
    return this.httpClient.get<ReferencesResponse>(`${this.url()}/references`).pipe(take(1), shareReplay(1));
  }
}
